import './App.css';
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, doc, addDoc, setDoc, getDoc } from "firebase/firestore";
import Neis from "@my-school.info/neis-api";

const neis = new Neis({ KEY: "41a29aeda063494ebed4932f8eab7a20", Type: "json" });

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAyzO1a_WIMvMdqt5mMKe8xE4XLbRUk8wI",
  authDomain: "sample-50dd6.firebaseapp.com",
  projectId: "sample-50dd6",
  storageBucket: "sample-50dd6.appspot.com",
  messagingSenderId: "172593759052",
  appId: "1:172593759052:web:46eff2d6ef5f277cdf85e9"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);




function getToday(){
  var date = new Date();
  var year = date.getFullYear();
  var month = ("0" + (1 + date.getMonth())).slice(-2);
  var day = ("0" + date.getDate()).slice(-2);

  return year + month + day;
}


const school = await neis.getSchoolInfo({ SCHUL_NM: "상산고등학교" });
const mealInfo = await neis.getMealInfo({ ATPT_OFCDC_SC_CODE: school[0].ATPT_OFCDC_SC_CODE, SD_SCHUL_CODE: school[0].SD_SCHUL_CODE, MLSV_FROM_YMD: getToday(), MLSV_TO_YMD: getToday() });

console.log(mealInfo);


await setDoc(doc(db, "mealinfo", getToday()), {
  breakfast: mealInfo[0].DDISH_NM.split("<br/>"),
  lunch: mealInfo[1].DDISH_NM.split("<br/>"),
  dinner: mealInfo[2].DDISH_NM.split("<br/>")
});



const docRef = doc(db, "mealinfo", getToday());
const docSnap = await getDoc(docRef);
const breakfastInfo = docSnap.data().breakfast;
if (docSnap.exists()) {
  console.log("Document data:", breakfastInfo);
} else {
  // docSnap.data() will be undefined in this case
  console.log("No such document!");
}
const LunchInfo = docSnap.data().lunch;
if (docSnap.exists()) {
  console.log("Document data:", LunchInfo);
} else {
  // docSnap.data() will be undefined in this case
  console.log("No such document!");
}
const DinnerInfo = docSnap.data().dinner;
if (docSnap.exists()) {
  console.log("Document data:", DinnerInfo);
} else {
  // docSnap.data() will be undefined in this case
  console.log("No such document!");
}


function App() {
  return (
    <div className="py-8 sm:py-12 font-sans px-4 bg-[#fbfbfb]">
      <div className="max-w-xl mx-auto w-full space-y-10">
        <header className="space-y-3">
          <h1 className="text-3xl md:text-[2.5rem] font-extrabold flex justify-center items-center">
            상산급식
          </h1>
          <p className="text-base md:text-lg flex justify-center items-center ">오늘의 급식을 알려드립니다.</p>
        </header>
        <div className='space-y-4'>
        <div className='text-xl font-bold flex justify-center items-center space-y-2'>
          아침
        </div>
        <a className="w-full border border-gray-300 rounded-lg py-3 px-4 flex items-center gap-4 bg-white -space-y-2">
          <ul className='leading-relaxed' >
          {breakfastInfo.map(item => {
            return <li key ={item}> {item}</li>;
         })}
         </ul>  
       </a>
       </div>
       <div className='space-y-4'>
    <div className='text-xl font-bold flex justify-center items-center space-y-2'>
          점심
        </div>
        
    <a
      className="w-full border border-gray-300 rounded-lg py-3 px-4 flex items-center gap-4 bg-white"
    >
      <ul className='leading-relaxed' >
        {LunchInfo.map(item => {
          return <li key ={item}> {item}</li>;
        })}
        </ul>  
    </a>
    </div>
    <div className='space-y-4'>
    <div className='text-xl font-bold flex justify-center items-center space-y-2'>
          저녁
        </div>
    <a
      className="w-full border border-gray-300 rounded-lg py-3 px-4 flex items-center gap-4 bg-white"
    >
      <ul className='leading-relaxed' >
        {DinnerInfo.map(item => {
          return <li key ={item}> {item}</li>;
        })}
        </ul>  
    </a>
    </div>
    
    <div className="flex justify-center items-center">
    더 많은 기능이 추가될 예정입니다.
    </div>
        <footer className="mt-10 w-full border-t border-t-[#898ea4] pt-10 text-center text-sm text-gray-500 font-pretendard">© 2023 이찬슬</footer> 
      </div>
    </div>
  );
}

export default App;
